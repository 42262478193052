import {
  Flex,
  Text,
  Stack,
  Link,
  Box,
  useBreakpointValue,
  Heading,
  Icon,
  FlexProps,
} from '@chakra-ui/react';
import { FiInstagram, FiLink } from 'react-icons/fi';
import React from 'react';
import { BackgroundImageWrap, EmailAddress } from '@caseyagraff/web/ui';

import { motion } from 'framer-motion';

const MotionHeading = motion.custom(Heading);
const MotionStack = motion.custom(Stack);

const headingVariants = {
  hidden: { opacity: 0, y: 40 },
  visible: { opacity: 1, y: 0 },
};

const itemVariants = {
  hidden: { opacity: 0 },
  visible: { opacity: 1 },
};

function Home() {
  const backgroundTargetSrc = useBreakpointValue({
    base: 'assets/trees_md.jpg',
    md: 'assets/trees_md.jpg',
    lg: 'assets/trees_lg.jpg',
  });
  return (
    <Box w="100%" minH="100vh" position="relative">
      <BackgroundImageWrap
        baseSrc="assets/trees_sm.jpg"
        targetSrc={backgroundTargetSrc}
      />
      <Flex
        direction="column"
        h="50%"
        w="100%"
        zIndex={2}
        top="50%"
        position="absolute"
        color="gray.800"
        backgroundImage="linear-gradient(rgba(109, 154, 104, .85), rgba(109, 154, 104, .40))"
      >
        <Flex flexGrow={1} direction="column">
          <Box
            position="absolute"
            w="300px"
            h="300px"
            top="-150px"
            left="calc(50% - 150px)"
            borderRadius="300px"
            zIndex={4}
            color="white"
          >
            <Flex align="center" justify="center" w="100%" h="100%">
              <Heading as="h1" fontSize="7xl" textAlign="center">
                Casey Graff
              </Heading>
            </Flex>
          </Box>
          <Box
            color="white"
            position="absolute"
            top="-156px"
            left="calc(50% - 150px)"
            w="300px"
            h="150px"
            borderRadius="150px 150px 0 0"
            border="2px"
            borderWidth="6px"
            borderBottom={0}
            borderColor="background"
            boxSizing="content-box"
            ml="-6px"
            bg="centerBackground"
          ></Box>
          <Box
            position="absolute"
            top="0px"
            left="calc(50% - 150px)"
            w="300px"
            h="150px"
            borderRadius="0 0 150px 150px"
            overflow="hidden"
          >
            <Box
              w="100vw"
              h="100vh"
              position="absolute"
              backgroundImage={`url(${backgroundTargetSrc})`}
              backgroundRepeat="no-repeat"
              backgroundAttachment="fixed"
              backgroundSize="cover"
              display="block"
            >
              <Box
                h="100%"
                w="100%"
                bg="centerBackground"
                display="block"
              ></Box>
            </Box>
          </Box>
          <Stack
            position="absolute"
            top={{ base: '100%', lg: 16 }}
            pt={4}
            spacing={{ base: 8, lg: 0 }}
            w="100%"
            direction={{ base: 'column', lg: 'row' }}
            pb={4}
          >
            <Stack
              flexBasis={0}
              flexGrow={1}
              align="center"
              spacing={{ base: 4, lg: 10, xl: 16 }}
            >
              <MotionHeading
                as="h2"
                fontSize={{ base: '2xl', md: '3xl' }}
                initial="hidden"
                animate="visible"
                variants={headingVariants}
                transition={{ duration: 0.75 }}
                textTransform="uppercase"
              >
                <Flex align="center">
                  Developer
                  <Link href="https://sierravalleysoftware.com" isExternal>
                    <Icon as={FiLink} ml={2} fontSize="2xl" />
                  </Link>
                </Flex>
              </MotionHeading>
              <MotionStack
                spacing={{ base: 4, lg: 8 }}
                initial="hidden"
                animate="visible"
                variants={itemVariants}
                transition={{ duration: 0.75, delay: 1.5 }}
                align={{ base: 'center', lg: 'flex-start' }}
              >
                <Item name="ComplyAuto" links={['https://complyauto.com']} />
                <Item name="FitEQ International" />
              </MotionStack>
            </Stack>
            <Stack
              flexBasis={0}
              flexGrow={1}
              align="center"
              spacing={{ base: 4, lg: 10, xl: 16 }}
            >
              <MotionHeading
                as="h2"
                fontSize={{ base: '2xl', md: '3xl' }}
                initial="hidden"
                animate="visible"
                variants={headingVariants}
                transition={{ duration: 0.75, delay: 0.5 }}
                textTransform="uppercase"
              >
                <Flex align="center">
                  Researcher
                  <Link
                    href="https://scholar.google.com/citations?user=m71pElEAAAAJ&hl=en&oi=ao"
                    isExternal
                  >
                    <Icon as={FiLink} ml={2} fontSize="2xl" />
                  </Link>
                </Flex>
              </MotionHeading>
              <MotionStack
                spacing={{ base: 4, lg: 8 }}
                initial="hidden"
                animate="visible"
                variants={itemVariants}
                transition={{ duration: 0.75, delay: 1.5 }}
                align={{ base: 'center', lg: 'flex-start' }}
              >
                <Item
                  name="Wildfire Forecasting"
                  links={[
                    'https://ieeexplore.ieee.org/abstract/document/9016356/',
                    'http://www.publish.csiro.au/wf/wf19023',
                    'https://agupubs.onlinelibrary.wiley.com/doi/abs/10.1029/2019MS001955',
                  ]}
                />
                <Item
                  name="Zooplankton Classification"
                  links={[
                    'https://aslopubs.onlinelibrary.wiley.com/doi/abs/10.1002/lom3.10324',
                    'https://ieeexplore.ieee.org/abstract/document/7838125/',
                  ]}
                />
              </MotionStack>
            </Stack>
          </Stack>
          <Flex
            h="100%"
            justify="center"
            align="center"
            mt={{ base: '40%', md: '250px', lg: '300px' }}
          >
            <Text color="gray.200" fontSize={{ base: '2xl', md: '3xl' }} mt={8}>
              <EmailAddress email="caseyagraff@gmail.com" />
            </Text>
          </Flex>
        </Flex>

        <Flex
          py={2}
          px={{ base: 5, md: 9, lg: 10 }}
          color="gray.200"
          opacity=".75"
        >
          <Text flexGrow={1}></Text>

          <Stack
            direction="row"
            align="center"
            display={{ base: 'none', lg: 'flex' }}
          >
            <Text>Photo by Adam Vradenburg</Text>
            <Link href="https://www.instagram.com/adamvradenburg/" isExternal>
              <FiInstagram />
            </Link>
          </Stack>
        </Flex>
      </Flex>
    </Box>
  );
}

interface ItemProps extends FlexProps {
  name: string;
  links?: string[];
}

function Item({ name, links, ...flexProps }: ItemProps) {
  return (
    <Heading
      as="h3"
      fontSize={{ base: 'xl', sm: '2xl', md: '3xl', lg: '3xl', xl: '4xl' }}
      textAlign={{ base: 'center', lg: 'left' }}
    >
      <Flex align="center" {...flexProps}>
        <Box as="span">{name}</Box>

        {links && (
          <Stack direction="row" ml={2}>
            {links.map((link, ind) => (
              <Link key={link} href={link} isExternal>
                {links.length > 1 ? (
                  <Text
                    color="gray.700"
                    fontSize={{ base: 'lg', sm: 'xl', md: '2xl' }}
                  >
                    [{ind + 1}]
                  </Text>
                ) : (
                  <Icon as={FiLink} fontSize="2xl" />
                )}
              </Link>
            ))}
          </Stack>
        )}
      </Flex>
    </Heading>
  );
}

export default Home;
