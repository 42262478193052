import { Text, Box, BoxProps } from '@chakra-ui/react';
import React from 'react';

interface RoundedTextProps extends BoxProps {
  text: string;
  offset?: number;
  isSemiCircle?: boolean;
  radius: number;
}

function RoundedText({
  text,
  radius,
  offset = -90,
  isSemiCircle = true,
  ...boxProps
}: RoundedTextProps) {
  const totalDegress = isSemiCircle ? 180 : 360;
  const degreesPerLetter = totalDegress / (text.length - 1);

  return (
    <Text>
      {text.split('').map((char, ind) => (
        <Box
          key={ind}
          fontSize="4xl"
          fontWeight="bold"
          fontFamily="mono"
          as="span"
          position="absolute"
          top="0px"
          left={`${radius - 12}px`}
          transform={`rotate(${degreesPerLetter * ind + offset}deg)`}
          transformOrigin="bottom center"
          height={radius}
          {...boxProps}
        >
          {char}
        </Box>
      ))}
    </Text>
  );
}

export default RoundedText;
