import React from 'react';

import { Box, ChakraProvider } from '@chakra-ui/react';
import { Theme } from '@caseyagraff/web/ui';
import Home from '../screens/Home';

export function App() {
  return (
    <ChakraProvider theme={Theme}>
      <div className="app" style={{ width: '100%', minHeight: '100vh' }}>
        <Box w="100%" h="100%" bg="background" overflow="auto">
          <Home />
        </Box>
      </div>
    </ChakraProvider>
  );
}

export default App;
