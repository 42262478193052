import React, { useState } from 'react';
import { Box, Image } from '@chakra-ui/react';

interface BackgroundImageWrapProps {
  baseSrc: string;
  targetSrc: string;
}

function BackgroundImageWrap({ baseSrc, targetSrc }: BackgroundImageWrapProps) {
  const [imageLoaded, setImageLoaded] = useState(false);
  const baseBackground = (
    <Box
      w="100%"
      h="100%"
      position="absolute"
      backgroundImage={`url(${baseSrc})`}
      backgroundRepeat="no-repeat"
      backgroundAttachment="fixed"
      backgroundSize="cover"
      display={imageLoaded ? 'block' : 'block'}
    ></Box>
  );

  const targetBackground = (
    <Box
      w="100%"
      h="100%"
      position="absolute"
      backgroundImage={`url(${targetSrc})`}
      backgroundRepeat="no-repeat"
      backgroundAttachment="fixed"
      backgroundSize="cover"
      display={imageLoaded ? 'block' : 'none'}
    ></Box>
  );

  return (
    <>
      {baseBackground}
      {targetBackground}
      <Image
        src={targetSrc}
        onLoad={() => setImageLoaded(true)}
        display="none"
        ignoreFallback
      />
    </>
  );
}

export default BackgroundImageWrap;
