import React from 'react';

interface EmailAddressProps {
  email: string;
}

function EmailAddress({ email }: EmailAddressProps) {
  return (
    <>
      {email.split('').map((char, ind) => (
        <span key={ind}>{char}</span>
      ))}
    </>
  );
}

export default EmailAddress;
