import { extendTheme } from '@chakra-ui/react';

export const theme = extendTheme({
  colors: {
    centerBackground: 'rgba(109, 154, 104, .20)',
    background: 'rgba(109, 154, 104, .85)',
  },
  fonts: {
    body: 'Roboto, system-ui, sans-serif',
    heading: 'Roboto, sans-serif;',
  },
});
